import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const ProfessorPage = lazy(() =>
	import("./professor/ProfessorPage")
);

const AlunoPage = lazy(() =>
	import("./aluno/AlunoPage")
);

const InstituicaoPage = lazy(() =>
	import("./instituicao/InstituicaoPage")
);

const TurmaPage = lazy(() =>
	import("./turma/TurmaPage")
);

const CursoPage = lazy(() =>
	import("./curso/CursoPage")
);

const DisciplinaPage = lazy(() =>
	import("./disciplina/DisciplinaPage")
);

const QuestaoPage = lazy(() =>
	import("./questao/QuestaoPage")
);

const AtividadePage = lazy(() =>
	import("./atividade/AtividadePage")
);

const NotaPage = lazy(() =>
	import("./nota/NotaPage")
);

const AvaliacaoPage = lazy(() =>
	import("./avaliacao/AvaliacaoPage")
);

const AplicacaoPage = lazy(() =>
	import("./aplicacao/AplicacaoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact from="/" to="/dashboard" />}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/professor" component={ProfessorPage} />
				<Route path="/aluno" component={AlunoPage} />
				<Route path="/instituicao" component={InstituicaoPage} />
				<Route path="/turma" component={TurmaPage} />
				<Route path="/curso" component={CursoPage} />
				<Route path="/disciplina" component={DisciplinaPage} />
				<Route path="/questao" component={QuestaoPage} />
				<Route path="/atividade" component={AtividadePage} />
				<Route path="/nota" component={NotaPage} />
				<Route path="/avaliacao" component={AvaliacaoPage} />
				<Route path="/aplicacao" component={AplicacaoPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
