import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { connect } from "react-redux";

class MenuList extends React.Component {
	render() {
		const { currentUrl, menuConfig, layoutConfig, user } = this.props;

		const configMenu = (title) => {
			if ((user.adm_tipo === "Professor" && ["Acessos", "Instituições"].includes(title)) || (user.adm_tipo === "Instituição" && ["Acessos"].includes(title))) {
				return false;
			}

			return true;
		};

		return menuConfig.aside.items.filter((child) => configMenu(child.title)).map((child, index) => {
			return (
				<React.Fragment key={`menuList${index}`}>
					{child.section && <MenuSection item={child} />}
					{child.separator && <MenuItemSeparator item={child} />}
					{child.title && (
						<MenuItem
							item={child}
							currentUrl={currentUrl}
							layoutConfig={layoutConfig}
						/>
					)}
				</React.Fragment>
			);
		});
	}
}

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(MenuList);
