import axios from "axios";
import { Constants } from '../utils/Constants';

export function login(email, password) {
	return axios.post(`${Constants.baseUrl}/auth`, { email, password });
}

export function forgotPassword(email) {
	return axios.post(`${Constants.baseUrl}/forgot-password`, { email });
}

export function resetPassword(data) {
	return axios.post(`${Constants.baseUrl}/reset-password`, data);
}
