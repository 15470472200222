export default {
    header: {
        self: {},
        items: [{}],
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "fas fa-tachometer-alt",
                page: "dashboard",
                bullet: "dot",
            },
            {
                title: "Professores",
                root: true,
                icon: "fas fa-chalkboard-teacher",
                page: "professor",
                bullet: "dot",
            },
            {
                title: "Alunos",
                root: true,
                icon: "fas fa-user-friends",
                page: "aluno",
                bullet: "dot",
            },
            {
                title: "Turmas",
                root: true,
                icon: "fas fa-users",
                page: "turma",
                bullet: "dot",
            },
            {
                title: "Instituições",
                root: true,
                icon: "fas fa-university",
                page: "instituicao",
                bullet: "dot",
            },
            {
                title: "Disciplinas",
                root: true,
                icon: "fas fa-list",
                page: "disciplina",
                bullet: "dot",
            },
            {
                title: "Cursos",
                root: true,
                icon: "fas fa-graduation-cap",
                page: "curso",
                bullet: "dot",
            },
            {
                title: "Questões",
                root: true,
                icon: "fas fa-question-circle",
                page: "questao",
                bullet: "dot",
            },
            {
                title: "Avaliações",
                root: true,
                icon: "fas fa-tasks",
                page: "avaliacao",
                bullet: "dot",
            },
            {
                title: "Aplicação de avaliações",
                root: true,
                icon: "fas fa-stream",
                page: "aplicacao",
                bullet: "dot",
            },
            {
                title: "Atividades",
                root: true,
                icon: "fas fa-book",
                page: "atividade",
                bullet: "dot",
            },
            {
                title: "Relatórios",
                root: true,
                icon: "fas fa-chart-pie",
                bullet: "dot",
                submenu: [
                    {
                        title: "Atividades",
                        bullet: "dot",
                        page: "relatorio/atividade",
                    },
                    {
                        title: "Nível de avaliação",
                        bullet: "dot",
                        page: "relatorio/avaliacao",
                    },
                    {
                        title: "Participação",
                        bullet: "dot",
                        page: "relatorio/participacao",
                    },
                    {
                        title: "Resultado de avaliação",
                        bullet: "dot",
                        page: "relatorio/questao",
                    },
                ],
            },
            {
                title: "Acessos",
                root: true,
                icon: "fas fa-user-shield",
                bullet: "dot",
                submenu: [
                    {
                        title: "Administradores",
                        page: "admin",
                        bullet: "dot",
                    },
                    {
                        title: "Grupos de Acesso",
                        page: "group",
                        bullet: "dot",
                    },
                ],
            },
        ],
    },
};
