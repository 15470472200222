import axios from "axios";

export const regexEmail = /^[\w+.-]+@\w+\.\w{2,}(?:\.\w{2})?$/;

export const createFormikValidation = (formMap, values) => {
    const errors = {};

    for (let [key, value] of formMap) {
        if (!values[key] && values[key] !== 0) {
            errors[key] = "O campo " + value + " é obrigatório.";
        } else if ((key === "email" || key === "pro_email") && !regexEmail.test(values[key])) {
            errors[key] = "E-mail informado é inválido.";
        } else if (key === "pro_cpf" && !validaCpf(values[key])) {
            errors[key] = "O CPF informado é inválido.";
        }
    }

    return errors;
};

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const formatHourInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1:$2");

    input.target.value = value;

    return input;
};

export const formatCNPJInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatCPFInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatDataInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");
    value = value.replace(/(\d{2})(\d)/, "$1/$2");

    input.target.value = value;

    return input;
};

export const formatTelefoneInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{0})(\d)/, "$1($2");
    value = value.replace(/(\d{2})(\d)/, "$1) $2");
    value = value.replace(/(\d{1})(\d{1,4})$/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatCepInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d)/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatBRLInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, "")) / 10 ** 2;

    input.target.value = formatBRL(input.target.value);

    return input;
};

export const formatDecimalNota = (e) => {
    e.target.value = e.target.value.replace(",", ".");

    const decimalRegex = /^\d*\.?\d{0,1}$/;

    if (!decimalRegex.test(e.target.value) || e.target.value < 0 || e.target.value > 10) {
        e.target.value = 10;
    }

    return e;
};

export const formatBRL = (value) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    value = formatter.format(value);

    return value;
};

export const buscaCep = (input, setFieldValue, nomeEndereco, nomeBairro, nomeCidade, nomeUf) => {
    if (input.target.value.length > 8) {
        var cep = input.target.value.replace(/[^0-9]/, "");

        const instance = axios.create();

        instance
            .get(`https://viacep.com.br/ws/${cep}/json/`, {})
            .then((res) => {
                setFieldValue(nomeEndereco, res.data.logradouro);
                setFieldValue(nomeBairro, res.data.bairro);
                setFieldValue(nomeCidade, res.data.localidade);
                setFieldValue(nomeUf, res.data.uf);
            })
            .catch((err) => {});
    }

    return formatCepInput(input);
};

export const limparMoeda = (valor) => {
    valor = valor.replace("R$", "");
    valor = valor.replace(".", "");
    valor = valor.replace(",", ".");
    // eslint-disable-next-line
    valor = valor.replace("/^p{Z}+|p{Z}+$/u", "");
    valor = valor.trim();

    return valor;
};

export const listaUfs = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"];

export const listaSimNao = [
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
];

export const listaStatus = [
    { value: 1, label: "Ativo" },
    { value: 0, label: "Inativo" },
];

export const listaNivel = [
    { value: "Nível 1", label: "Nível 1" },
    { value: "Nível 2", label: "Nível 2" },
    { value: "Nível 3", label: "Nível 3" },
    { value: "Nível 4", label: "Nível 4" },
    { value: "Nível 5", label: "Nível 5" },
];

export const displayValueCurrency = (value) => {
    return value ? `R$ ${value.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}` : "Não disponível";
};

export const displayValueFixed = (value, casas) => {
    return value ? value.toFixed(casas) : "Não disponível";
};

export const displayValuePercent = (value) => {
    return value ? `${value.toLocaleString("pt-BR", { minimumFractionDigits: 2 })} %` : "Não disponível";
};

export const formatNumber = (value) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    value = formatter.format(value);

    return value;
};

export const formatPercentInput = (input) => {
    input.target.value = Number(input.target.value.toString().replace(/[^0-9-]/g, ""));

    if (Math.ceil(input.target.value) === 0) {
        input.target.value = "";
        return input.target.value;
    }

    if (input.target.value > 100) return 100 + "%";

    input.target.value = input.target.value + "%";
    input.target.setSelectionRange(input.target.value.length - 1, input.target.value.length - 1);

    return input.target.value;
};

export const formatIntegerInput = (input) => {
    input.target.value = input.target.value.toString().replace(/[^0-9]/g, "");

    return input;
};

export const formatContaBancoInput = (input) => {
    let value = input.target.value;

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d*)(\d)/, "$1-$2");

    input.target.value = value;

    return input;
};

export const formatNumberNota = (input) => {
    const x = input.target.value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,1})/);
    input.target.value = !x[2] ? x[1] : x[1] + "." + x[2];

    return input;
};

export const validaCpf = (cpf) => {
    var add = 0;
    var rev = 0;

    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf === "") return false;

    if (cpf.length !== 11) return false;

    for (var i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;

    if (rev !== parseInt(cpf.charAt(9))) return false;

    add = 0;

    for (var j = 0; j < 10; j++) {
        add += parseInt(cpf.charAt(j)) * (11 - j);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;

    if (rev !== parseInt(cpf.charAt(10))) return false;

    return true;
};

export const listaBancos = [
    { key: "001", name: "BANCO DO BRASIL" },
    { key: "341", name: "ITAÚ" },
    { key: "104", name: "CAIXA ECONÔMICA FEDERAL" },
    { key: "033", name: "SANTANDER" },
    { key: "070", name: "BRB - BANCO DE BRASÌLIA" },
    { key: "077", name: "BANCO INTERMEDIUM" },
    { key: "237", name: "BRADESCO" },
    { key: "745", name: "CITIBANK" },
    { key: "422", name: "BANCO SAFRA" },
    { key: "399", name: "BANCO HSBC" },
    { key: "756", name: "BANCOOB" },
    { key: "212", name: "BANCO ORIGINAL" },
    { key: "002", name: "BANCO CENTRAL DO BRASIL" },
    { key: "003", name: "BANCO DA AMAZONIA S.A" },
    { key: "004", name: "BANCO DO NORDESTE DO BRASIL S.A" },
    { key: "007", name: "BANCO NAC DESENV. ECO. SOCIAL S.A" },
    { key: "008", name: "BANCO MERIDIONAL DO BRASIL" },
    { key: "020", name: "BANCO DO ESTADO DE ALAGOAS S.A" },
    { key: "021", name: "BANCO DO ESTADO DO ESPIRITO SANTO S.A" },
    { key: "022", name: "BANCO DE CREDITO REAL DE MINAS GERAIS SA" },
    { key: "024", name: "BANCO DO ESTADO DE PERNAMBUCO" },
    { key: "025", name: "BANCO ALFA S/A" },
    { key: "026", name: "BANCO DO ESTADO DO ACRE S.A" },
    { key: "027", name: "BANCO DO ESTADO DE SANTA CATARINA S.A" },
    { key: "028", name: "BANCO DO ESTADO DA BAHIA S.A" },
    { key: "029", name: "BANCO DO ESTADO DO RIO DE JANEIRO S.A" },
    { key: "030", name: "BANCO DO ESTADO DA PARAIBA S.A" },
    { key: "031", name: "BANCO DO ESTADO DE GOIAS S.A" },
    { key: "032", name: "BANCO DO ESTADO DO MATO GROSSO S.A." },
    { key: "034", name: "BANCO DO ESADO DO AMAZONAS S.A" },
    { key: "035", name: "BANCO DO ESTADO DO CEARA S.A" },
    { key: "036", name: "BANCO DO ESTADO DO MARANHAO S.A" },
    { key: "037", name: "BANCO DO ESTADO DO PARA S.A" },
    { key: "038", name: "BANCO DO ESTADO DO PARANA S.A" },
    { key: "039", name: "BANCO DO ESTADO DO PIAUI S.A" },
    { key: "041", name: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A" },
    { key: "047", name: "BANCO DO ESTADO DE SERGIPE S.A" },
    { key: "048", name: "BANCO DO ESTADO DE MINAS GERAIS S.A" },
    { key: "059", name: "BANCO DO ESTADO DE RONDONIA S.A" },
    { key: "106", name: "BANCO ITABANCO S.A." },
    { key: "107", name: "BANCO BBM S.A" },
    { key: "109", name: "BANCO CREDIBANCO S.A" },
    { key: "116", name: "BANCO B.N.L DO BRASIL S.A" },
    { key: "148", name: "MULTI BANCO S.A" },
    { key: "151", name: "CAIXA ECONOMICA DO ESTADO DE SAO PAULO" },
    { key: "153", name: "CAIXA ECONOMICA DO ESTADO DO R.G.SUL" },
    { key: "165", name: "BANCO NORCHEM S.A" },
    { key: "166", name: "BANCO INTER-ATLANTICO S.A" },
    { key: "168", name: "BANCO C.C.F. BRASIL S.A" },
    { key: "175", name: "CONTINENTAL BANCO S.A" },
    { key: "184", name: "BBA - CREDITANSTALT S.A" },
    { key: "199", name: "BANCO FINANCIAL PORTUGUES" },
    { key: "200", name: "BANCO FRICRISA AXELRUD S.A" },
    { key: "201", name: "BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A" },
    { key: "204", name: "BANCO S.R.L S.A" },
    { key: "205", name: "BANCO SUL AMERICA S.A" },
    { key: "206", name: "BANCO MARTINELLI S.A" },
    { key: "208", name: "BANCO PACTUAL S.A" },
    { key: "210", name: "DEUTSCH SUDAMERIKANICHE BANK AG" },
    { key: "211", name: "BANCO SISTEMA S.A" },
    { key: "213", name: "BANCO ARBI S.A" },
    { key: "214", name: "BANCO DIBENS S.A" },
    { key: "215", name: "BANCO AMERICA DO SUL S.A" },
    { key: "216", name: "BANCO REGIONAL MALCON S.A" },
    { key: "217", name: "BANCO AGROINVEST S.A" },
    { key: "218", name: "BS2" },
    { key: "219", name: "BANCO DE CREDITO DE SAO PAULO S.A" },
    { key: "220", name: "BANCO CREFISUL" },
    { key: "221", name: "BANCO GRAPHUS S.A" },
    { key: "222", name: "BANCO AGF BRASIL S. A." },
    { key: "223", name: "BANCO INTERUNION S.A" },
    { key: "224", name: "BANCO FIBRA S.A" },
    { key: "225", name: "BANCO BRASCAN S.A" },
    { key: "228", name: "BANCO ICATU S.A" },
    { key: "229", name: "BANCO CRUZEIRO S.A" },
    { key: "230", name: "BANCO BANDEIRANTES S.A" },
    { key: "231", name: "BANCO BOAVISTA S.A" },
    { key: "232", name: "BANCO INTERPART S.A" },
    { key: "233", name: "BANCO MAPPIN S.A" },
    { key: "234", name: "BANCO LAVRA S.A." },
    { key: "235", name: "BANCO LIBERAL S.A" },
    { key: "236", name: "BANCO CAMBIAL S.A" },
    { key: "239", name: "BANCO BANCRED S.A" },
    { key: "240", name: "BANCO DE CREDITO REAL DE MINAS GERAIS S." },
    { key: "241", name: "BANCO CLASSICO S.A" },
    { key: "242", name: "BANCO EUROINVEST S.A" },
    { key: "243", name: "BANCO STOCK S.A" },
    { key: "244", name: "BANCO CIDADE S.A" },
    { key: "245", name: "BANCO EMPRESARIAL S.A" },
    { key: "246", name: "BANCO ABC ROMA S.A" },
    { key: "247", name: "BANCO OMEGA S.A" },
    { key: "249", name: "BANCO INVESTCRED S.A" },
    { key: "250", name: "BANCO SCHAHIN CURY S.A" },
    { key: "251", name: "BANCO SAO JORGE S.A." },
    { key: "252", name: "BANCO FININVEST S.A" },
    { key: "254", name: "BANCO PARANA BANCO S.A" },
    { key: "255", name: "MILBANCO S.A." },
    { key: "256", name: "BANCO GULVINVEST S.A" },
    { key: "258", name: "BANCO INDUSCRED S.A" },
    { key: "261", name: "BANCO VARIG S.A" },
    { key: "262", name: "BANCO BOREAL S.A" },
    { key: "263", name: "BANCO CACIQUE" },
    { key: "264", name: "BANCO PERFORMANCE S.A" },
    { key: "265", name: "BANCO FATOR S.A" },
    { key: "266", name: "BANCO CEDULA S.A" },
    { key: "267", name: "BANCO BBM-COM.C.IMOB.CFI S.A." },
    { key: "275", name: "BANCO REAL S.A" },
    { key: "277", name: "BANCO PLANIBANC S.A" },
    { key: "282", name: "BANCO BRASILEIRO COMERCIAL" },
    { key: "291", name: "BANCO DE CREDITO NACIONAL S.A" },
    { key: "294", name: "BCR - BANCO DE CREDITO REAL S.A" },
    { key: "295", name: "BANCO CREDIPLAN S.A" },
    { key: "300", name: "BANCO DE LA NACION ARGENTINA S.A" },
    { key: "302", name: "BANCO DO PROGRESSO S.A" },
    { key: "303", name: "BANCO HNF S.A." },
    { key: "304", name: "BANCO PONTUAL S.A" },
    { key: "308", name: "BANCO COMERCIAL BANCESA S.A." },
    { key: "318", name: "BANCO B.M.G. S.A" },
    { key: "320", name: "BANCO INDUSTRIAL E COMERCIAL" },
    { key: "346", name: "BANCO FRANCES E BRASILEIRO S.A" },
    { key: "347", name: "BANCO SUDAMERIS BRASIL S.A" },
    { key: "351", name: "BANCO BOZANO SIMONSEN S.A" },
    { key: "353", name: "BANCO GERAL DO COMERCIO S.A" },
    { key: "356", name: "ABN AMRO S.A" },
    { key: "366", name: "BANCO SOGERAL S.A" },
    { key: "369", name: "PONTUAL" },
    { key: "370", name: "BEAL - BANCO EUROPEU PARA AMERICA LATINA" },
    { key: "372", name: "BANCO ITAMARATI S.A" },
    { key: "375", name: "BANCO FENICIA S.A" },
    { key: "376", name: "CHASE MANHATTAN BANK S.A" },
    { key: "388", name: "BANCO MERCANTIL DE DESCONTOS S/A" },
    { key: "389", name: "BANCO MERCANTIL DO BRASIL S.A" },
    { key: "392", name: "BANCO MERCANTIL DE SAO PAULO S.A" },
    { key: "394", name: "BANCO B.M.C. S.A" },
    { key: "409", name: "UNIBANCO - UNIAO DOS BANCOS BRASILEIROS" },
    { key: "412", name: "BANCO NACIONAL DA BAHIA S.A" },
    { key: "415", name: "BANCO NACIONAL S.A" },
    { key: "420", name: "BANCO NACIONAL DO NORTE S.A" },
    { key: "424", name: "BANCO NOROESTE S.A" },
    { key: "434", name: "BANCO FORTALEZA S.A" },
    { key: "453", name: "BANCO RURAL S.A" },
    { key: "456", name: "BANCO TOKIO S.A" },
    { key: "464", name: "BANCO SUMITOMO BRASILEIRO S.A" },
    { key: "466", name: "BANCO MITSUBISHI BRASILEIRO S.A" },
    { key: "472", name: "LLOYDS BANK PLC" },
    { key: "473", name: "BANCO FINANCIAL PORTUGUES S.A" },
    { key: "477", name: "CITIBANK N.A" },
    { key: "479", name: "BANCO DE BOSTON S.A" },
    { key: "480", name: "BANCO PORTUGUES DO ATLANTICO-BRASIL S.A" },
    { key: "483", name: "BANCO AGRIMISA S.A." },
    { key: "487", name: "DEUTSCHE BANK S.A - BANCO ALEMAO" },
    { key: "488", name: "BANCO J. P. MORGAN S.A" },
    { key: "489", name: "BANESTO BANCO URUGAUAY S.A" },
    { key: "492", name: "INTERNATIONALE NEDERLANDEN BANK N.V." },
    { key: "493", name: "BANCO UNION S.A.C.A" },
    { key: "494", name: "BANCO LA REP. ORIENTAL DEL URUGUAY" },
    { key: "495", name: "BANCO LA PROVINCIA DE BUENOS AIRES" },
    { key: "496", name: "BANCO EXTERIOR DE ESPANA S.A" },
    { key: "498", name: "CENTRO HISPANO BANCO" },
    { key: "499", name: "BANCO IOCHPE S.A" },
    { key: "501", name: "BANCO BRASILEIRO IRAQUIANO S.A." },
    { key: "502", name: "BANCO SANTANDER S.A" },
    { key: "504", name: "BANCO MULTIPLIC S.A" },
    { key: "505", name: "BANCO GARANTIA S.A" },
    { key: "600", name: "BANCO LUSO BRASILEIRO S.A" },
    { key: "601", name: "BFC BANCO S.A." },
    { key: "602", name: "BANCO PATENTE S.A" },
    { key: "604", name: "BANCO INDUSTRIAL DO BRASIL S.A" },
    { key: "607", name: "BANCO SANTOS NEVES S.A" },
    { key: "608", name: "BANCO OPEN S.A" },
    { key: "610", name: "BANCO V.R. S.A" },
    { key: "611", name: "BANCO PAULISTA S.A" },
    { key: "612", name: "BANCO GUANABARA S.A" },
    { key: "613", name: "BANCO PECUNIA S.A" },
    { key: "616", name: "BANCO INTERPACIFICO S.A" },
    { key: "617", name: "BANCO INVESTOR S.A." },
    { key: "618", name: "BANCO TENDENCIA S.A" },
    { key: "621", name: "BANCO APLICAP S.A." },
    { key: "622", name: "BANCO DRACMA S.A" },
    { key: "623", name: "BANCO PAnameRICANO S.A" },
    { key: "624", name: "BANCO GENERAL MOTORS S.A" },
    { key: "625", name: "BANCO ARAUCARIA S.A" },
    { key: "626", name: "BANCO FICSA S.A" },
    { key: "627", name: "BANCO DESTAK S.A" },
    { key: "628", name: "BANCO CRITERIUM S.A" },
    { key: "629", name: "BANCORP BANCO COML. E. DE INVESTMENTO" },
    { key: "630", name: "BANCO INTERCAP S.A" },
    { key: "633", name: "BANCO REDIMENTO S.A" },
    { key: "634", name: "BANCO TRIANGULO S.A" },
    { key: "635", name: "BANCO DO ESTADO DO AMAPA S.A" },
    { key: "637", name: "BANCO SOFISA S.A" },
    { key: "638", name: "BANCO PROSPER S.A" },
    { key: "639", name: "BIG S.A. - BANCO IRMAOS GUIMARAES" },
    { key: "640", name: "BANCO DE CREDITO METROPOLITANO S.A" },
    { key: "641", name: "BANCO EXCEL ECONOMICO S/A" },
    { key: "643", name: "BANCO SEGMENTO S.A" },
    { key: "645", name: "BANCO DO ESTADO DE RORAIMA S.A" },
    { key: "647", name: "BANCO MARKA S.A" },
    { key: "648", name: "BANCO ATLANTIS S.A" },
    { key: "649", name: "BANCO DIMENSAO S.A" },
    { key: "650", name: "BANCO PEBB S.A" },
    { key: "652", name: "BANCO FRANCES E BRASILEIRO SA" },
    { key: "653", name: "BANCO INDUSVAL S.A" },
    { key: "654", name: "BANCO A. J. RENNER S.A" },
    { key: "655", name: "BANCO VOTORANTIM S.A." },
    { key: "656", name: "BANCO MATRIX S.A" },
    { key: "657", name: "BANCO TECNICORP S.A" },
    { key: "658", name: "BANCO PORTO REAL S.A" },
    { key: "702", name: "BANCO SANTOS S.A" },
    { key: "705", name: "BANCO INVESTCORP S.A." },
    { key: "707", name: "BANCO DAYCOVAL S.A" },
    { key: "711", name: "BANCO VETOR S.A." },
    { key: "713", name: "BANCO CINDAM S.A" },
    { key: "715", name: "BANCO VEGA S.A" },
    { key: "718", name: "BANCO OPERADOR S.A" },
    { key: "719", name: "BANCO PRIMUS S.A" },
    { key: "720", name: "BANCO MAXINVEST S.A" },
    { key: "721", name: "BANCO CREDIBEL S.A" },
    { key: "722", name: "BANCO INTERIOR DE SAO PAULO S.A" },
    { key: "724", name: "BANCO PORTO SEGURO S.A" },
    { key: "725", name: "BANCO FINABANCO S.A" },
    { key: "726", name: "BANCO UNIVERSAL S.A" },
    { key: "728", name: "BANCO FITAL S.A" },
    { key: "729", name: "BANCO FONTE S.A" },
    { key: "730", name: "BANCO COMERCIAL PARAGUAYO S.A" },
    { key: "731", name: "BANCO GNPP S.A." },
    { key: "732", name: "BANCO PREMIER S.A." },
    { key: "733", name: "BANCO NACOES S.A." },
    { key: "734", name: "BANCO GERDAU S.A" },
    { key: "735", name: "BANCO NEON" },
    { key: "736", name: "BANCO UNITED S.A" },
    { key: "737", name: "THECA" },
    { key: "738", name: "MARADA" },
    { key: "739", name: "BGN" },
    { key: "740", name: "BCN BARCLAYS" },
    { key: "741", name: "BRP" },
    { key: "742", name: "EQUATORIAL" },
    { key: "743", name: "BANCO EMBLEMA S.A" },
    { key: "744", name: "THE FIRST NATIONAL BANK OF BOSTON" },
    { key: "746", name: "MODAL S\\A" },
    { key: "747", name: "RAIBOBANK DO BRASIL" },
    { key: "748", name: "SICREDI" },
    { key: "749", name: "BRMSANTIL SA" },
    { key: "750", name: "BANCO REPUBLIC NATIONAL OF NEW YORK (BRA" },
    { key: "751", name: "DRESDNER BANK LATEInameRIKA-BRASIL S/A" },
    { key: "752", name: "BANCO BANQUE NATIONALE DE PARIS BRASIL S" },
    { key: "753", name: "BANCO COMERCIAL URUGUAI S.A." },
    { key: "755", name: "BANCO MERRILL LYNCH S.A" },
    { key: "757", name: "BANCO KEB DO BRASIL S.A." },
    { key: "260", name: "NUBANK" },
    { key: "102", name: "XP INVESTIMENTOS" },
    { key: "336", name: "BANCO C6 S.A." },
    { key: "290", name: "PagSeguro Internet S.A." },
    { key: "323", name: "MercadoPago.com Representações Ltda." },
    { key: "332", name: "Acesso Soluções de Pagamento S.A." },
];

export const listaTipoTurma = [
    { value: "Semestral", label: "Semestral" },
    { value: "Anual", label: "Anual" },
];

export const listaPeriodo = [
    { value: "Integral", label: "Integral" },
    { value: "Matutino", label: "Matutino" },
    { value: "Noturno", label: "Noturno" },
    { value: "Vespertino", label: "Vespertino" },
];

export const listaTipoQuestao = [
    { value: "Múltipla escolha", label: "Múltipla escolha" },
    { value: "Complete", label: "Complete" },
    { value: "Descritiva", label: "Descritiva" },
    { value: "Somatório", label: "Somatório" },
    { value: "Relacione", label: "Relacione" },
    { value: "Objetiva", label: "Objetiva" },
    { value: "Verdadeiro ou falso", label: "Verdadeiro ou falso" },
];

export const listaAtividadeAplicarPor = [
    { value: "Alunos", label: "Alunos" },
    { value: "Turma", label: "Turma" },
];

export const listaAvaliacaoAplicarPor = [
    { value: "Alunos", label: "Alunos" },
    { value: "Turma", label: "Turma" },
    { value: "Grupos", label: "Grupos" },
];

export const listaResposta = [
    { value: "Correta", label: "Correta" },
    { value: "Parcial", label: "Parcial" },
    { value: "Incorreta", label: "Incorreta" },
];

export const addZero = (numero) => {
    return numero < 10 ? `0${numero}` : numero;
};

export const tamanhoPapel = (quantidade) => {
    if (quantidade < 4) {
        return "A4";
    } else if (quantidade < 6) {
        return "A3";
    } else if (quantidade < 9) {
        return "A2";
    } else {
        return "A1";
    }
};
