import { createFormikValidation } from "../../utils/Utils";

export const validateFormCadastro = (values) => {
	let formMap = new Map();

	// 

	return createFormikValidation(formMap, values);
};

export const validateLogin = (values) => {
	let errors = {};

	// 

	return errors;
};

export const validateEsqueceuSenha = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = "O email é uma informação obrigatória.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	return errors;
};

export const validateAlterarSenha = (values) => {
	let errors = {};

	if (!values.password) {
		errors.password = "A senha é uma informação obrigatória.";
	} else if (values.password.length < 6) {
		errors.password = "Sua nova senha precisa ter no mínimo 6 caracteres.";
	}

	if (!values.password_confirmation) {
		errors.password_confirmation = "A confirmação de senha é uma informação obrigatória.";
	}


	if (values.password !== values.password_confirmation) {
		errors.password_confirmation = "As senhas não são iguais.";
	}

	return errors;
};